<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.my-tickets') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/tickets/my-tickets" class="breadcrumbs-link"> {{ $t('labels.my-tickets')
                }}</router-link>
            </div>
          </div>
          <div class="subheader-right">
            <v-guard :permissions="['tickets-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                <b-dropdown-group id="dropdown-group-1" header="Excel">
                  <b-dropdown-item-button @click="$refs.ticketlist.exportResultsEstimate('tickets', 'excel')">{{
                    $t('labels.tickets') }}
                    A
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.ticketlist.exportComments('my-tickets')">{{ $t('labels.events')
                    }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
              <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{
                $t('labels.wait') }}
              </button>
            </v-guard>
            <v-guard :permissions="['tickets-hide-show-button']">
              <router-link v-if="!hideCompleted" :to="{ name: 'tickets.my-tickets', query: { 'hide-completed': 1 } }"
                @click.native="$router.go(0);">
                <button class="btn btn-secondary"><i class="fas fa-minus-circle"></i> {{ $t('labels.hide-completed')
                  }}</button>
              </router-link>
              <router-link v-if="hideCompleted" :to="{ name: 'tickets.my-tickets', query: { 'hide-completed': 0 } }"
                @click.native="$router.go(0);">
                <button class="btn btn-secondary"><i class="fas fa-plus-circle"></i> {{ $t('labels.show-completed')
                  }}</button>
              </router-link>
            </v-guard>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <tickets-list :enable-filter="$store.getters.permissions.includes('tickets-page-mytickets-use-filter')"
          ref="ticketlist" :defaultQuery="query" :title="$t('labels.my-tickets')"></tickets-list>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Notifications from '@/components/dashboard/Notifications.vue';
import TicketsList from '@/components/lists/ticket/TicketsList.vue';

export default {
  name: 'home',
  components: {
    LayoutMain,
    Notifications,
    TicketsList,
  },
  data() {
    return {
      isDownloading: false,
      hideCompleted: false,
    };
  },
  created() {
    if ('hide-completed' in this.$route.query) {
      this.hideCompleted = !!+this.$route.query['hide-completed'];
    }
  },
  methods: {

  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    defaultQuery() {

      const formedlaExternt = this.$store.getters.permissions.includes('ticket-view-formedla_externt');
      const excludedYears = ['2017', '2018', '2019', '2020'];

      let defQuery = {
        query: {
          query: {
            bool: {
              must: [
                {
                  nested: {
                    path: 'users',
                    query: {
                      bool: {
                        filter: [
                          {
                            match: {
                              'users.id': this.user.user.id,
                            },
                          },
                        ],
                      },
                    },
                  },
                },
              ],
              must_not: [
                {
                  terms: {'year.raw': excludedYears},
                }
              ]
            }
          }
        }
      };

      let ticketStatusesToExclude = '';
      if(this.hideCompleted) {
        ticketStatusesToExclude = 'Avslutat';
      }
      if (!formedlaExternt) {
        ticketStatusesToExclude += ',Förmedlad externt';
      }

      if (ticketStatusesToExclude.length > 0) {
        defQuery.query.query.bool.must_not.push({
          match: {
            ticketstatus: ticketStatusesToExclude,
          },
        });
      }

      return defQuery;

    },
    query() {
      return {
        isDownloading: false,
        query: this.defaultQuery
      };
    },
  },
};
</script>
